<script>
import { mapState } from "vuex";
import Layout from "../../layouts/main";
import { api } from "@/state/services";

export default {
  components: { Layout },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  data() {
    return {
      id: this.$route.params.id,
      username: null,
      avatar: null,
      matriz: '',
      loading: true,
    }
  },
  methods: {
    getIndications() {
      api
        .get('network/matriz/'+this.id)
        .then(response => {
          if (response.data.status == 'success') {
            this.loading = false
            this.matriz = response.data.list;
            this.username = response.data.user.username;
            this.avatar = response.data.user.avatar;
          }
        })
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id
    } else {
      this.id = this.account.user.id
    }

    this.getIndications();
  },
  watch: {
    '$route.params.id': function (id) {
      if (id) {
        this.id = id
      } else {
        this.id = this.account.user.id
      }

      this.getIndications()
    }
  }
}
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="tree p-0">
            <div class="wrapper">
              <div class="item">
                <div class="item-parent">
                  <p>{{ username }}</p>
                  <img v-if="avatar" :src="avatar" class="card-img avatar rounded-circle" />
                  <img v-else src="@/assets/images/logo-artesanato-icon.png" class="card-img avatar rounded-circle" />
                </div>
                <div class="item-childrens">
                  <div class="item-child" v-for="(mat, index) in matriz" :key="index">
                    <p>{{ mat.username }}</p>
                    <div v-if="mat.username">
                      <router-link :to="'/network/matriz/'+mat.id">
                        <img v-if="mat.avatar" :src="mat.avatar" class="card-img avatar rounded-circle" />
                        <img v-else src="@/assets/images/logo-artesanato-icon.png" class="card-img avatar rounded-circle" />
                      </router-link>
                    </div>
                    <img v-else src="@/assets/images/logo-artesanato-icon.png" class="card-img avatar rounded-circle no-matriz" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style>
  .tree {
    transform: rotate(270deg);
  }

  .wrapper {
    display: flex;
    height: 100%;
    justify-content: center;
  }

  .item {
    display: flex;
    flex-direction: row-reverse;
    margin: 10px;
  }

  .item img {
    margin: 5px;
    width: 75px;
    height: 70px;
    transform: rotate(90deg);
    cursor: pointer;
  }

  .item p {
    margin-top: 10px;
    text-align: center;
    height: 70px;
    width: 100%;
    transform: rotate(90deg);
  }

  .item-parent {
    position: relative;
    margin-left: 50px;
    display: flex;
    align-items: center;
  }

  .item-parent:after {
    position: absolute;
    content: '';
    width: 25px;
    height: 2px;
    left: 0;
    top: 50%;
    background-color: rgb(163, 157, 157);
    transform: translateX(-100%);
  }

  .item-childrens {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .item-child {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;
  }

  .item-child:before {
    content: '';
    position: absolute;
    background-color: rgb(163, 157, 157);
    right: 0;
    top: 50%;
    transform: translateX(100%);
    width: 25px;
    height: 2px;
  }

  .item-child:after {
    content: '';
    position: absolute;
    background-color: rgb(163, 157, 157);
    right: -25px;
    height: calc(50% + 68px);
    width: 2px;
    top: 50%;
  }

  .item-child:last-child:after {
    transform: translateY(-100%);
  }

  .item-child:only-child:after {
    display: none;
  }

  .no-matriz {
    filter: grayscale(100%);
    opacity: 0.7;
    cursor: auto !important;
  }

  .loading-matriz {
    width: 100%;
    transform: rotate(90deg);
  }

  @media (max-width: 725px) {
    .tree {
      overflow-y: visible;
      /* height: 100%; */
    }
  }
</style>